import React, { useEffect } from "react"
import "../theme/courses.less"
import Seo from "../components/seo"
import Container from "../components/Courses/Container"
import CourseDetail from "../components/Courses/CourseDetail"

const CoursesPage = () => {
  // useEffect(() => {
  //   //TAWK.to
  //   var Tawk_API = Tawk_API || {}

  //   var s1: any = document.createElement("script")
  //   var s0: any = document.getElementsByTagName("script")[0]
  //   s1.async = true
  //   s1.src = "https://embed.tawk.to/622f1fada34c2456412af169/1fu417g0k"
  //   s1.charset = "UTF-8"
  //   s1.setAttribute("crossorigin", "*")
  //   s0.parentNode.insertBefore(s1, s0)
  // }, [])

  return (
    <Container>
      <Seo title="Mentro: Learn with Mentors" />
      <CourseDetail />
    </Container>
  )
}

export default CoursesPage
